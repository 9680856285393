import React, {Component} from 'react';
// routing import
import {Route} from 'react-router-dom';
// general views import
import ApplicationLayout from '../views/application/ApplicationLayout';
import {MuiThemeProvider, Hidden} from "@material-ui/core";
import ThemeDKC from './ThemeDKC';
import NoLoggedTheme from '../views/common/NoLoggedTheme';
import HeaderRoot from "../views/common/HeaderRoot";
import NoLoggedRoutes from "../views/common/NoLoggedRoutes";
import {
    Routing
} from "cobrains-react";
import Footer from "../views/common/FooterRoot";
import {compose} from "redux";
import {connect} from "react-redux";
import AdminTheme from "../views/application/ApplicationTheme";
import AuthService from "../services/AuthService";
import BreadCrumbs from '../components/ui/breadcrumbs/BreadCrumbs'

class RouteManager extends Component {

    getUserRoutes(userType, user){
        // Define if user can log in in the app
        if(userType === 'MT' || userType === 'MF' || userType === 'SU'){
            userType = 'application'
        }
        switch(userType){
            // Ubilod users
            case 'application': return (<Route path={'/'} render={props => <ApplicationLayout {...props} />}/>);
            // No Logged
            default: return (<NoLoggedRoute router={this.props.router}/>);
        }
    }

    render(){
        return (
            (this.props.user.is_logged) ?
                this.getUserRoutes(this.props.user.type, this.props.user)
                :
                <NoLoggedRoute router={this.props.router} user={this.props.user}/>
        );
    }
}

// =====================================
// NO LOGGED ROUTE
// =====================================
const NoLoggedRoute = (props) => (

    <MuiThemeProvider theme={ (props.user && props.user.company_id && props.user.company_id === 103) ? ThemeDKC: NoLoggedTheme}>
        {/* Header */}

        {(window.location.pathname !== '/login')?
            <HeaderRoot/>
        :null}

            {/* Breadcrumbs */}
            {(props.router.route && props.router.route.breadcrumbs)?
                <Hidden xsDown>
                <BreadCrumbs notLogged/>
                </Hidden>
            :null}

            <main style={(window.location.pathname !== '/' && window.location.pathname !== '/login')?{marginTop:102, padding : 40}:{marginTop:102, padding : 40}}>
            {/* Routing */}
                <Routing routes={NoLoggedRoutes}
                         isLogged={AuthService.isLogged()}
                         layout={""}
                         redirects={[
                             {from: '/machine', to: '/'},
                             {from: '*', to: '/login'}
                         ]}
                />
            </main>

        {/*{(window.location.pathname !== '/' && window.location.pathname !== '/login')?
            <Footer/>
        :null}*/}
    </MuiThemeProvider>
);

RouteManager.propTypes = {};


const mapStateToProps = state => ({
    router: state.router,
    user: state.user,
});

export default compose(connect(mapStateToProps))(RouteManager);
