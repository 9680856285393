import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
//import Components
import File from './File';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
//Icon Imports
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp'
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/Apps';
import CheckIcon from '@material-ui/icons/Check';
import ProductApi from "../../../../../services/ProductApi";
// file utils import
import FileUtils from '../../../../../utils/FileUtils';
import {updateApiControl} from "../../../../../redux/actions/api-control-action";
import {updateLoaders} from "../../../../../redux/actions/loaders-action";
import {updateMultilanguage} from "../../../../../redux/actions/multilanguage-action";
import {updateRouter} from "../../../../../redux/actions/router-action";
import {compose} from "redux";
import {connect} from "react-redux";
import FileViewer from "../fileViewer/FileViewer";

const styles = theme => ({
    root: {
        width: '100%',
        overflowX : 'auto',
        padding: '20px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        },
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        fontSize: 14,
    },
    message: {
        color: theme.palette.gray.dark,
        fontSize: 18,
        fontWeight: 400,
    },
    filesHeader: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')] : {
            justifyContent: 'space-between'
}
    },
    table: {
    },
    active: {
        color: theme.palette.primary.main,
    },
    dropDownFlex: {
        flex: 1,
    },
    containerFileEdit: {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        backgroundColor: theme.palette.background.main,
        textAlign: 'center',
        paddingTop: 30,
        paddingBottom: 30,
        marginLeft: -40,
        marginRight: -40,
    }
});
class FileList extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: false,
            anchorOrder: null,
            anchorDisplayMode: null,
            orderField: 'name',
            orderBy: 0,
            displayMode: 1,
            files: [],
            selectedFile: null,
            displayAllCheck: false,
            openFileViewer: false,
            lastSelectedFile: null
        }
    }

    componentWillMount(){
        this.sortFiles(0, this.props.files);
    }

    componentWillReceiveProps(nextProps){
        this.sortFiles(this.state.displayMode, nextProps.files);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.files !== this.props.files) {
            this.sortFiles(this.state.orderBy, this.props.files)
        }
    }

    // gestione stato aperto dropdown
    handleOpenOrder = event => { this.setState({ anchorOrder : event.currentTarget}) };
    handleOpenDisplayMode = event => { this.setState({ anchorDisplayMode : event.currentTarget}) };

    // gestione stato chiuso dropdown
    handleCloseOrder = () => { this.setState({ anchorOrder: null })};
    handleCloseDisplayMode = () => { this.setState({ anchorDisplayMode: null })};

    // gestione della modalità di display dei file (grid, lista)
    handleDisplayMode = (mode) => {
        this.setState({ displayMode : mode });
        this.handleCloseDisplayMode();
    };

    // gestione dell'ordinamento dei file per nome
    handleOrderBy = (mode, files) => {
        this.setState({ orderBy: mode });
        this.sortFiles(mode, files);
        this.handleCloseOrder();
    };

    sortFiles = (mode, files) => {
        (mode === 0) ?
            files.findIndex((item) => item.name === null) === -1 &&
            files.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : 1) : files.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? -1 : 1);


        this.setState({ files : files});
    };

    // ==================================================
    // ON SELECT CHECKBOX
    // ==================================================

    isBetween = (n, a, b) => {
        return (n - a) * (n - b) <= 0
    }

    handleFileCheckbox = (file, e) => {

        let selectedFiles = [...this.props.selectedFiles];

        if (e && e.nativeEvent.shiftKey && this.state.lastSelectedFile !== null){

            const nextValue = this.getNextValue(file);

            let tmpFiles = [...this.props.files];
            let tmpSelected = [this.state.lastSelectedFile];

            let indexStart = tmpFiles.findIndex(item => item.id === this.state.lastSelectedFile.id);
            let indexEnd = tmpFiles.findIndex(item => item.id === file.id);

            tmpFiles.map((item, index) => {
                if (this.isBetween(index, indexStart, indexEnd)){
                    (selectedFiles.findIndex(sel => sel.id === item.id) === -1) ?
                        tmpSelected.push(item)
                        :
                        (tmpSelected.findIndex(sel => sel.id !== item.id) !== -1 && this.state.lastSelectedFile.id !== item.id) &&
                        tmpSelected.splice(tmpSelected.findIndex(sel => sel.id === item.id), 1)
                }
                });



            this.props.onSelectFile(tmpSelected)
        }
        else {
            const index = this.props.selectedFiles.indexOf(file);
            (index >= 0) ? selectedFiles.splice(index, 1) : selectedFiles.push(file);
            const displayAllCheck = selectedFiles.length > 0;
            this.setState({displayAllCheck: displayAllCheck});
            this.props.onSelectFile(selectedFiles);
        }
        this.setState({lastSelectedFile : file})
        // call onResult function to set the searchResult
};

    getNextValue(value, e) {

        let selectedItems = [...this.props.selectedFiles]
        const hasBeenSelected = selectedItems.findIndex(item => item.id === value.id) !== -1;

        if (e && e.nativeEvent.shiftKey) {
            const newSelectedItems = this.getNewSelectedItems(value);
            // de-dupe the array using a Set
            const selections = [...new Set([...selectedItems, ...newSelectedItems])];

            if (!hasBeenSelected) {
                return selections.filter(item => !newSelectedItems.includes(item));
            }

            return selections;
        }

        // if it's already in there, remove it, otherwise append it
        return selectedItems.includes(value)
            ? selectedItems.filter(item => item !== value)
            : [...selectedItems, value];
    }

    getNewSelectedItems(value) {
        const { lastSelectedFile } = this.state;
        let items = [...this.props.files]
        const currentSelectedIndex = items.findIndex(item => item.id === value.id);
        const lastSelectedIndex = items.findIndex(
            item => item.id === lastSelectedFile.id
        );

        return items
            .slice(
                Math.min(lastSelectedIndex, currentSelectedIndex),
                Math.max(lastSelectedIndex, currentSelectedIndex) + 1
            )
            .map(item => item);
    }



    // ===================================
    // CLICK ON FILE - PREVIEW
    // ===================================
    handleOpenFileView(file){
        ProductApi.getFileById(this.props.product.id,file.id).then(
            res => {
                var blob = new Blob([res.data], {type: FileUtils.getFileType(file.name)});
                //var blobURL = URL.createObjectURL(blob,{oneTimeOnly:true});

                var anchor = document.createElement("a");
                if(navigator.userAgent.indexOf("Chrome") !== -1 ||navigator.userAgent.indexOf("Opera") !== -1 ) { // Chrome - Opera on Browser
                    var a = document.createElement('a');
                    a.target="_blank";
                    a.href=URL.createObjectURL(blob, {oneTimeOnly: true});
                    a.click();
                }else if(navigator.userAgent.indexOf("Safari") !== -1 || navigator.userAgent.indexOf("Firefox") !== -1){
                    window.location = URL.createObjectURL(blob, {oneTimeOnly: true});
                }else if (navigator.userAgent.match('CriOS')) { // Chrome iOS phone
                    var reader = new FileReader();
                    reader.onloadend = function () { window.open(reader.result);};
                    reader.readAsDataURL(blob);
                }else if(navigator.userAgent.indexOf("iPad") !== -1){
                    var fileURL = URL.createObjectURL(blob);
                    //var anchor = document.createElement("a");
                    anchor.download="myPDF.pdf";
                    anchor.href = fileURL;
                    anchor.click();
                }else if (window.navigator.msSaveOrOpenBlob) { //IE 11+
                    window.navigator.msSaveOrOpenBlob(blob, "my.pdf");
                } else if (navigator.userAgent.match('FxiOS')) { //FF iOS
                    alert("Cannot display on FF iOS");
                }
            },
            () => {
                alert('errore')
            }
        )

    }

    // ===================================
    // RIGHT CLICK MENU ON FILE
    // ===================================
    handleOnDownloadFile = (file) => {
        alert('download')
    };
    handleOnDeleteFile = (file) => {
        alert('delete')
    };


    render(){
        const { anchorOrder, anchorDisplayMode, orderBy, displayMode, files,openFileViewer } = this.state;
        const { classes, readonly, product, model, category, languageValues, isModel = false, multilanguage } = this.props;

        const gridList = <div className={classes.grid}>
                            {files.map((file, i) =>
                                <File
                                    onDownloadFile={() => this.handleOnDownloadFile(file)}
                                    onDeleteFile={() => this.handleOnDeleteFile(file)}
                                    //onClickFile={() => (FileUtils.getFileType(file.name) === 'video') ? this.setState({selectedFile: file, openFileViewer: true}) : this.handleOpenFileView(file)}
                                    onClickFile={() => this.setState({selectedFile: file, openFileViewer: true})}
                                    file={file}
                                    readonly={readonly || (file && (file.is_model_file && isModel === false))}
                                    mode={0}
                                    onClickCheckbox={(e) => this.handleFileCheckbox(file, e)}
                                    fileChecked={ this.props.selectedFiles.indexOf(file) >= 0 }
                                    displayAllCheck={this.state.displayAllCheck}
                                    key={file.id}
                                >
                                </File>
                            )}
                        </div>;

        const tableList = <Table className={classes.table}>
                                <TableBody>
                                    {files.map((file, i) =>
                                        <File
                                            onDownloadFile={() => this.handleOnDownloadFile(file)}
                                            onDeleteFile={() => this.handleOnDeleteFile(file)}
                                            onClickFile={() => this.setState({selectedFile: file, openFileViewer: true})}
                                            file={file}
                                            readonly={readonly || (file && file.is_model_file && isModel === false)}
                                            mode={1}
                                            languageValues={multilanguage.languageValues}
                                            onClickCheckbox={(e) => this.handleFileCheckbox(file, e)}
                                            fileChecked={ this.props.selectedFiles.indexOf(file) >= 0 }
                                            displayAllCheck={this.state.displayAllCheck}
                                            key={file.id}
                                        />)}
                                </TableBody>
                            </Table>;

        const listFiles = (
            <div className={classes.fileListTable}>
                <div className={classes.filesHeader}>
                    <div style={{display: 'flex', flex: 1, justifyItems: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography
                            className={classes.typography}
                            aria-owns={anchorOrder ? 'fade-order' : null}
                            aria-haspopup="true"
                            onClick={this.handleOpenOrder.bind(this)}>
                            Nome { (orderBy===0) ? <ArrowUpIcon/> : <ArrowDownIcon/> }
                        </Typography>
                        <Menu
                            id="fade-order"
                            anchorEl={anchorOrder}
                            open={Boolean(anchorOrder)}
                            onClose={this.handleCloseOrder}
                            style={{marginTop: 50}}>

                            <MenuItem onClick={this.handleOrderBy.bind(this, 0, this.state.files)} className={(orderBy===0) ? classes.active : null }>
                                { (orderBy===0) ? <CheckIcon className={classes.dropDownFlex} /> : <div className={classes.dropDownFlex} /> }
                                <span className={classes.dropDownFlex} >Ascendente</span>
                            </MenuItem>
                            <MenuItem onClick={this.handleOrderBy.bind(this, 1, this.state.files)} className={(orderBy===1) ? classes.active : null }>
                                { (orderBy===1) ? <CheckIcon className={classes.dropDownFlex} /> : <div className={classes.dropDownFlex} /> }
                                <span className={classes.dropDownFlex} >Discendente</span>
                            </MenuItem>
                        </Menu>
                    </div>

                    <div style={{display: 'flex', flex: 1, justifyItems: 'center', alignItems: 'center', cursor: 'pointer', flexFlow: 'row-reverse' }}>
                        <Typography
                            className={classes.typography}
                            aria-owns={anchorDisplayMode ? 'fade-display-mode' : null}
                            aria-haspopup="true"
                            onClick={this.handleOpenDisplayMode.bind(this)}>
                            { (displayMode===0) ? <GridIcon/> : <ListIcon/> }  <ArrowDownIcon />
                        </Typography>
                        <Menu
                            id="fade-display-mode"
                            anchorEl={anchorDisplayMode}
                            open={Boolean(anchorDisplayMode)}
                            onClose={this.handleCloseDisplayMode}
                            style={{marginTop: 50}}>
                            <MenuItem onClick={this.handleDisplayMode.bind(this, 0)} className={(displayMode===0) ? classes.active : null }>
                                { (displayMode===0) ? <CheckIcon className={classes.dropDownFlex} /> : <div className={classes.dropDownFlex} /> }
                                <span className={classes.dropDownFlex} >Griglia</span>
                                <GridIcon className={classes.dropDownFlex} />
                            </MenuItem>
                            <MenuItem onClick={this.handleDisplayMode.bind(this, 1)} className={(displayMode===1) ? classes.active : null }>
                                { (displayMode===1) ? <CheckIcon className={classes.dropDownFlex} /> : <div className={classes.dropDownFlex} /> }
                                <span className={classes.dropDownFlex}>Elenco</span>
                                <ListIcon className={classes.dropDownFlex} />
                            </MenuItem>
                        </Menu>
                    </div>
                </div>

                <Divider style={{marginLeft: 6, marginRight: 10, marginTop: 5}} />

                <div className={ (displayMode === 0) ? classes.filesGridContainer : classes.filesTableContainer}>
                    { (displayMode === 0) ? gridList : tableList }
                </div>
            </div>
        );





        /*if(this.state.redirectToFileManager && this.state.selectedFile) {
            if(this.props.model) {
                return <Redirect to={`/model/${model.id}/category/${category.id}/file/${this.state.selectedFile.id}`} />
            } else {
                return <Redirect to={`/machine/${product.id}/category/${category.id}/file/${this.state.selectedFile.id}`} />
            }
        }*/

        return (
            <div className={classes.root}>
                {listFiles}


                {openFileViewer &&
                    <FileViewer
                    open={openFileViewer}
                    onClose={(reason) => {
                        this.setState({openFileViewer: false});
                    }}
                    type={this.props.model ? 'model': 'machine'}
                    fileId={this.state.selectedFile.id}
                    id={this.props.model ? model.id : product.id}
                    />
                }
            </div>
        );
    }
}

FileList.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    files: PropTypes.array,
    readonly: PropTypes.bool,
    model: PropTypes.bool,
};


const mapStateToProps = state => ({
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
};


export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(FileList);
