import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
    root : {
        width : '100%',
        borderRadius : 20,
        border: '1px solid #CAD2DA',
        flexDirection : 'column',
        padding : 40,
        [theme.breakpoints.down('xs')] : {
            padding : '40px 12px'
        }
    },
    container : {
        display : 'flex',
        justifyContent : 'flex-start',
        flexDirection : 'column',
        },
    iconContainer:{
        borderRadius: 5,
        minWidth : 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems : 'center',
        marginRight : 20,
        fontSize : 30,
        color : '#F9C74F',
        fontWeight: 500
    },
    textContainer:{
        display : 'flex',
        flexDirection : 'column',
        justifyContent :'center'
    },
    value : {
        fontSize : 24,
        fontWeight : '500',
        lineHeight : 1.2
    },
    title : {
        fontSize: 16,
        color : '#000',
        opacity : 0.4,
        textTransform : 'uppercase',
        fontStyle : 'italic'
    },
    text : {
        fontSize: 18,
        color : '#000',
        opacity : 0.4
    },
    machineContainer:{
        display : 'flex',
        marginTop : 24
    }

}));

function DashMachineCard (props){

    const classes = useStyles(props);
    const {
        color,
        icon,
        data,
        text = '123',
        value = '',
        machines = [
            {name : data.name_product_1, views : data.qr_product_1},
            {name : data.name_product_2, views : data.qr_product_2},
            {name : data.name_product_3, views : data.qr_product_3},
            ]
    } = props;

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>QR PIÙ VISUALIZZATI</Typography>
                <div className={classes.container}>
                    {machines.map((item, index) =>
                <div className={classes.machineContainer}>
                    <div className={classes.iconContainer} style={{backgroundColor : color}}>
                        {index + 1}
                    </div>
                    <div className={classes.textContainer}>
                        <Typography className={classes.value}>{item.name}</Typography>
                        <Typography className={classes.text}>{item.views} visualizzazioni</Typography>
                    </div>
                </div>
                    )}
            </div>
        </div>
    );
}

export default DashMachineCard;