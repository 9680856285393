import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
import { updateLoaders } from '../../../../../redux/actions/loaders-action';
import { updateRouter } from "../../../../../redux/actions/router-action";
// Material-ui
import Typography from '@material-ui/core/Typography';
// Api
import ProductApi from '../../../../../services/ProductApi';
import ModelApi from '../../../../../services/ModelApi';
// containers import
import "video-react/dist/video-react.css"
// Components
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
// fileutils import
import FileUtils from '../../../../../utils/FileUtils';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
// image zoom
// doc viewer
import GdocViewer from '../../../../../components/ui/docViewer/GdocViewer';
// audio player
import ReactAudioPlayer from 'react-audio-player';
// video player
import { Player } from 'video-react';
import {translate} from "../../../../../translations/i18n";
import {DownloadCloud } from 'react-feather'
import Lightbox from 'react-image-lightbox';
import {Dialog, Tooltip, Button} from "@material-ui/core";
import {ArrowLeft, Download} from 'react-feather'
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

const styles  = (theme) => ({
    rounded:{
      borderRadius: 0
    },
    noSupportContainer:{
        textAlign:'center'
    },
    container : {
      backgroundColor : 'rgb(11 17 23 / 70%)'
    },
    btn: {
        backgroundColor: theme.palette.active.main,
        fontSize: 16,
        padding: '11px 16px 9px 16px !important',
        borderRadius: 5,
        fontWeight: 'bold',
        width: 'max-content',
        minHeight: 20,
        lineHeight: 'unset',
        '& > span:first-child': {
            textTransform: 'capitalize',
            alignItems : 'flex-end'
        }
    },
    paperFullWidth: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        margin : '0px !important',
        maxWidth : 'unset !important',
        overflowY : 'hidden',
        backgroundColor : 'transparent',
        boxShadow: 'inset 0px 0px 24px 3px rgba(0,0,0,0.75) !important'
    },
    scrollBody:{
        overflowY : 'hidden'
    },
    root:{
        position: 'relative',
        width: '70%',
        margin : '56px 0px 0px 0px',
        height: 'calc(100vh - 56px)',
        background: 'transparent',
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    },
    progress:{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerRoot : {
        position : 'absolute',
        left : 0,
        top : 0,
        width : '100%',
        maxHeight : 56,
        padding : '8px 16px 0px',
        display : 'flex',
        justifyContent : 'space-between',
        alignItems: 'center',
        color : '#FFF'
    },
    headerSection : {
        display : 'flex',
        flexDirection : 'row',
        alignItems : 'center'
    }
});

class FileViewer extends Component {

    constructor(props){

        super(props);
        this.state = {
            loader: false,
            product: null,
            category: null,
            permissions: null,
            file: null,
            fileUrl: '',
            fileExtension: '',
            dialogLoader: false,
            docFileLoader: false,
            openDeleteConfirmDialog: false,
            open: false,
        }
    }

    componentDidMount(){
        this.setState({ loader: true });
        const type = this.props.type;
        let fileId = this.props.fileId;
        let id = this.props.id;

        if (type ==='machine') {
            this.getMachineFile(id, fileId)
        } else if (type ==='model') {
            this.getModelFile(id, fileId)
        } else {
            this.setState({ loader: false });
            this.props.onUpdateApiControl({status: 400}, 'getFile', );
        }
    }

    getMachineFile(productId, fileId) {
        ProductApi.getFileUrlById(productId, fileId).then(
            res => {
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeMachineName: res.data.product.name,
                    activeMachineLabels: res.data.product.labels,
                    activeCategoryName: res.data.file.category.label,
                    activeCategoryLabels: res.data.file.category.labels,
                    activeFileName: res.data.file.name,
                    activeFileLabels: res.data.file.labels
                });
                this.setState({
                    loader:false,
                    product: res.data.product,
                    file: res.data.file,
                    // permissions: res.data.permissions,
                });
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getFile');
            }
        )
    }

    getModelFile(modelId, fileId) {
        ModelApi.getFileUrlById(modelId, fileId).then(
            res => {
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeModelName: res.data.model.name,
                    activeModelLabels: res.data.model.labels,
                    activeCategoryName: res.data.file.category.label,
                    activeCategoryLabels: res.data.file.category.labels,
                    activeFileName: res.data.file.name,
                    activeFileLabels: res.data.file.labels
                });
                this.setState({
                    loader:false,
                    model: res.data.model,
                    file: res.data.file,
                    // permissions: res.data.permissions,
                });
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getFile', );
            }
        )
    }

    downloadFile() {
        const { file } = this.state;
        this.setState({ loader: true });

        fetch(file.url)
            .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = file.name;
                        a.click();
                        this.setState({loader : false})
                    });
                },
                err => {
                    let link = document.createElement('a', );
                    document.body.appendChild(link); // Firefox requires the link to be in the body
                    link.download = file.name;
                    link.setAttribute('download', file.name);
                    link.setAttribute('target' , "_blank");
                    link.href = file.url;
                    link.click();
                    document.body.removeChild(link)
                    this.setState({loader : false})
                }
            )
    }

    addToolbarFunction = (permissions) => {
        let toolBarFunctions = [];
        if(!permissions) { return toolBarFunctions; }

        if(permissions.delete){
            toolBarFunctions.push({label: translate('file.download', {}, "Scarica file"),
                disabled: false,
                icon: (style) => <DownloadCloud style={style}/>,
                func: () => this.downloadFile()});
            // toolBarFunctions.push({label: 'Elimina', disabled: false, icon: 'delete', func: () => this.setState({openDeleteConfirmDialog: true})});
        }
        return toolBarFunctions;
    };

    render() {
        const { file, loader} = this.state;
        const { classes, theme, open, onClose} = this.props;

        const RenderComponentFileType = (file) => {
            let filetype = FileUtils.getFileType(file.name);
            switch(filetype){
                case 'video':
                    return<Player fluid={false} width={'100%'}>
                        <source src={file.url} />
                    </Player>
                case 'image':
                    let decodeImgUrl = file.url;

                    return (
                        <div style={{
                            width : '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor : 'pointer'
                        }}>
                            <img alt={file.name}
                                 src={decodeImgUrl}
                                 style={{
                                     maxHeight : `calc(${window.innerHeight}px - 100px)`,
                                     height : 'auto',
                                     maxWidth : '100%'
                                 }}
                                 onClick={() => this.setState({open : true})}
                            />

                            {this.state.open && (
                                <Lightbox
                                    reactModalStyle={{
                                        overlay: {zIndex : 99999}
                                    }}
                                    mainSrc={decodeImgUrl}
                                    onCloseRequest={() => this.setState({ open: false })}
                                />
                            )}

                            {/*<ReactImageMagnify {...{
                        smallImage: {
                            alt: file.name,
                            isFluidWidth: true,
                            src: decodeImgUrl
                        },
                        largeImage: {
                            src: decodeImgUrl,
                            width: 2400,
                            height: 1600
                        },
                        enlargedImagePosition: 'over',
                        isEnlargedImagePortalEnabledForTouch: true
                    }} />*/}
                        </div>
                    );
                case 'doc':
                    let encodedUrl = encodeURIComponent(file.url);

                    return (
                        <div style={{
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}>
                            <GdocViewer onLoad={() => this.setState({docFileLoader:true})} url={encodedUrl}/>
                            {/*{!this.state.docFileLoader &&
                                <div className={classes.progress}>
                                    <CircularProgress size={52} color="secondary"></CircularProgress>
                                </div>
                            }*/}
                            <div className={classes.progress}>
                                <CircularProgress size={52} color="secondary"></CircularProgress>
                            </div>

                        </div>
                    );
                case 'audio':
                    return(
                        <ReactAudioPlayer
                            src={file.url}
                            autoPlay={false}
                            style={{width: '70%'}}
                            controls
                        />
                    );
                default:
                    return (
                        <div className={classes.noSupportContainer}>
                            <FontAwesomeIcon icon={faFileDownload} size="4x" style={{color:'#fff'}} />
                            <Typography style={{fontSize:18,margin:'20px 0', color : '#fff'}}>
                                {translate('file.format', {}, "Il formato di questo file non è visualizzabile da Ubilod")}
                            </Typography>
                            <Button type="button" variant="contained" color="primary"
                                    className={classes.btn}
                                    onClick={() => this.downloadFile()}>
                                {translate('file.download', {}, "Scarica file")}
                                <Download style={{marginLeft : 12}}/>
                            </Button>
                        </div>
                    )
            }
        };


        const FileHeader = () => {

        return (
        <div className={classes.headerRoot}>
            <div className={classes.headerSection}>
                <IconButton onClick={() => onClose()}>
                    <ArrowLeft color={'#fff'}/>
                </IconButton>

                <div style={{margin : '0px 20px 0px 8px'}}>
                {file?.name && FileUtils.getIcon(file.name, '18px')}
                </div>

                <Typography style={{fontSize:18}}>
                    {file?.name || ''}
                </Typography>
            </div>


            <div className={classes.headerSection}>
                <Tooltip title={'Download'}>
                <IconButton onClick={() => this.downloadFile()}>
                    <Download color={'#fff'}/>
                </IconButton>
                </Tooltip>
            </div>


            </div>
            )
        }

        return (
            <Dialog
                open={open}
                scroll={'body'}
                fullWidth
                classes={{
                    container : classes.container,
                    paperFullWidth : classes.paperFullWidth,
                    scrollBody : classes.scrollBody
                }}
                PaperProps={{
                    classes : {
                        rounded : classes.rounded
                    }
                }}
                Back
                onClose={(event, reason) => console.log({reason})}
            >
                <FileHeader/>
            <div className={classes.root}>

                {/*
                title : file.name,
                size: FileUtils.bytesToSize(file.size)
                */}

                {file && file.name && FileUtils.getFileType(file.name) === 'doc' &&
                    <div className={classes.progress}>
                        <CircularProgress size={52} color="secondary"></CircularProgress>
                    </div>
                }


                    {(!file)?
                        <div className={classes.progress}>
                            <CircularProgress size={52} color="secondary"></CircularProgress>
                        </div>
                        :
                        RenderComponentFileType(file)
                    }






                {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate('category.loader', {}, "Elaborazione in corso")}/>

            </div>
            </Dialog>
        )
    }

    onError(e) {
        console.log(e, 'error in file-viewer');
    }
}


FileViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
    utils: state.utils
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateLoaders: updateLoaders,
    onUpdateRouter: updateRouter,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(FileViewer);
