import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
// logo import
import Logo from '../../assets/logos/logo-brand-white.svg';
import LogoDKC from '../../assets/logos/dkc.png';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../redux/actions/api-control-action';
import Hidden from "@material-ui/core/Hidden";
import BreadCrumbs from "../../components/ui/breadcrumbs/BreadCrumbs";
import LogoProfileOpen from "../../assets/logos/Logo-DKC.png";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

const styles  = theme => ({
    appBar: {
        padding:'0 40px',
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarDKC: {
        padding:'0 40px',
        height: 103,
        boxShadow : 'unset',
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('xs')]:{
            height : 63,
            padding : 0
        }
    },
    appBarShift: {
        marginLeft: 290,
        width: `calc(100% - 290px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    button: {
        marginLeft: 12,
        minWidth:100,
    },
    buttonSign:{
        marginLeft: 12,
        minWidth:100,
        border: '1px solid ' + theme.palette.active.main,
        cursor:'pointer'
    },
    buttonSignDKC:{
        color :  theme.palette.active.main,
        marginLeft: 12,
        minWidth:100,
        border: '1px solid ' + theme.palette.active.main,
        cursor:'pointer'
    },
    toolbar:{
        height : '100%',
        width : '100%',
        // margin : '0px 40px',
        borderBottom : '1px solid rgba(0,0,0,0.3)',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            padding: '0px 20px',
            margin: '0px',
            borderBottom : 'unset',
        }
    },
});

class HeaderRoot extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount(){}

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                {/* AppBar*/}
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBarDKC , {
                        [classes.appBarShift]: this.props.open,
                    })}>
                    <Toolbar className={classes.toolbar} disableGutters={!this.props.open}>
                        <Hidden smUp>


                                <img alt={'ubilod-logo'} src={LogoProfileOpen} style={{
                                    height: '100%',
                                    padding: '16px 0px',
                                    margin : '0px auto',
                                    aspectRatio: '6/2'
                                }}
                                />

                        </Hidden>
                        <Hidden xsDown>
                        <div style={{flex:1}}>
                            <Link to={'/'}>
                                <img alt={'ubilod-logo'} src={LogoDKC} width={100} style={{marginRight:16}} />
                               {/* <Hidden smDown>
                                    <img alt={'ubilod-logo'} src={Logo} width={112} style={{marginTop:4}} />
                                </Hidden>
                                <Hidden smUp>
                                    <img alt={'ubilod-logo'} src={LogoProfile} width={50} style={{marginTop:4}} />
                                </Hidden>*/}
                            </Link>
                        </div>

                        {(this.props.router && this.props.router.route && this.props.router.route.path !== '/m' && this.props.router.route.path !== '/') ?
                            <Hidden xsDown>
                                <BreadCrumbs notLogged/>
                            </Hidden>
                            :
                            null
                        }

                        {/*
                        * rimosso per DKC
                        * */}
                        {/*<Link to={'/login'}>
                            <Button variant="outlined" color={(this.props.user.company_id === 103) ? "primary" : "secondary"} className={(this.props.user.company_id === 103) ? classes.buttonSignDKC : classes.buttonSign}>
                                Login
                            </Button>
                        </Link>*/}

                        {/*<Button disabled variant="contained" color="secondary" className={classes.button}>
                            Registrati
                        </Button>*/}
                        </Hidden>
                    </Toolbar>
                    {(this.props.router && this.props.router.route && this.props.router.route.path !== '/m' && this.props.router.route.path !== '/') ?
                        <Hidden smUp>
                            <BreadCrumbs notLogged/>
                        </Hidden>
                        :null}
                </AppBar>
            </div>
        )
    }
}


HeaderRoot.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderRoot);
